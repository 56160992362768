.tabContainer{
    min-height:78vh;
    max-width:100vw;
    display: flex;
    flex-direction: column;
}
.tabHeader{
    min-height: 5vh;
    width:80vw;
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 20px;
}
.tabHeader .tabs{
    height: 10vh;
    width:30vw;
    margin:0px auto;
    margin-top:-20px;
}
.tabBody{
    min-height:55vh;
    width:100%;
}
.tabBody .innerBody{
max-height:55vh;
width:90%;
margin:0 auto;
overflow-y: auto;
overflow-x: hidden;
}
.innerBody::-webkit-scrollbar{
    scroll-behavior: smooth;
}
.innerBody::-webkit-scrollbar-track{
    /* width:5px; */
}
.innerBody::-webkit-scrollbar-thumb{
width: 5px;
background-color: #551fff;
}
.tabFooter{
    min-height:10vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabBtn{
    height:50px;
    width:150px;
    border:none;
    border-bottom:1px solid #551fff;
    outline: none;
    background-color: transparent;
    color:black;
    font-weight: bold;
    font-size: larger;
    border-radius: 2px;
    cursor: pointer;
}
.tabBtn:last-child{
    border-right:1px solid #551fff;
}
.tabBtn:first-child{
    border-left:1px solid #551fff;
}
.tabActive{
    background: rgba(85, 31, 255, 0.2);
    border-radius: 6px 6px 0px 6px;
    font-size: white;
}