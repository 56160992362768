.image-container{
    height:100%;
    width:100%;
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.image-content-cards{
    height:250px;
    margin:10px 10px;
    width:250px;
    border-radius:10px;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.1),
    -1px -1px 5px 0 rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: white;
}
.image-content-card-image{
   height:80%;
   width:100%;
   margin:0 auto;
   border-radius: 10px;
   border-bottom-left-radius: 0px;
   border-bottom-right-radius: 0px;
   /* border:1px solid black; */
}
.image-content-card-image img{
    height:100%;
    width:100%;
    /* border:5px solid white; */
    border-radius: 10px;
    object-fit: cover;
    /* margin-left: px; */
    /* margin:5px auto; */
}
.image-content-card-footer{
    height:10%;
    width:98%;
    margin:0 auto;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-content-card-footer p{
    font-size: smaller;
}

.full-container{
    height:100%;
    width: 100%;
    /* border:2px solid green; */
    display: flex;
    flex-direction: column;
    cursor: pointer;
}
/* .image-content-cards input:checked + label > .image-content-card-image{
    border:5px solid #551fff;
    border-radius: 10px;
    /* border-bottom: none; 
} 
/* .image-content-cards input:checked + label > .image-content-card-footer{
    border:5px solid #404047;
    border-top:none;
} */