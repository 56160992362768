.setting-container{
    min-height:83vh;
    max-width:90vw;
    margin:0 auto;
    /* border:1px solid black; */
}
.setting-container .device{
    min-height: 83vh;
    width: 70vw;
    background: #FFFFFF;
box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
border-radius: 20px;
margin:0 10px;
}
.setting-container .device-image{
    min-height:83vh;
    width: 20vw;
    background: #FFFFFF;
box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
border-radius: 20px;
}
.setting-container .device .device-header{
    min-height:15vh;
    width:100%;
    border-bottom:5px solid #551fff;
}
.setting-container .device .device-body{
    min-height:67vh;
    width:100%;
    /* border:1px solid black; */
}
.device-header .device-header-left{
    flex:1;
    height:60%;
    /* border:1px solid black; */
    justify-content: flex-start;
}
.device-header .device-header-right{
    flex:1;
    height:60%;
    /* border:1px solid black; */
    justify-content: flex-end;
}
.device-header-left .screen-icon{
height:63px;
width:63px;
background: rgba(85,31,255,0.1);
border-radius: 16px;
margin:0 10px;
}
.device-header-left .screen-text{
    height:100%;
}
.device-header-left .screen-text p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #15192C;
    margin-top: 2px;
}
.device-header-left .screen-text span{
    font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #15192C;
opacity: 0.2;
margin-top: -20px;
}
.device .device-body .device-detail{
height:67vh;
flex:.7;
/* border:1px solid black; */
}
.device-body .device-graph{
    height:67vh;
    flex:0.3;
    /* border:1px solid black; */
}
.device-row{
    height:40%;
    width:100%;
    border-bottom: 1px solid rgba(85, 31, 255, 0.2);
}
.device-row:last-child{
    height: 19%;
    width:100%;
}
.device-row .device-row-left{
    height:100%;
    width:50%;
    /* border-right:1px solid black; */
}
.device-row .device-row-right{
    height:100%;
    width:50%;
    /* border:1px solid red; */
}
.info-1,.info-2{
    margin:10px 0;
}
.info-1 p,.info-2 p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* line-height: 48px; */
    color: #15192C;
    margin: 5px 0;
}
.info-1 span,.info-2 span{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 48px; */
    color: #92959E;
}

.deviceSelect:focus-visible {
    outline: unset !important;
}
.form-fields{
    margin: 5px 0;
    display:flex;
    justify-content: flex-end;
}