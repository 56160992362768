.dashboard-container {
    min-height: 83vh;
    width: 100%;
    /* border:1px solid black; */
}

.dashboard-left {
    height: 80vh;
    width: 70%;
    /* border:1px solid black; */
}

.dashboard-right {
    height: 80vh;
    width: 30%;
    /* border:1px solid black; */
}

.dashboard-right-upper {
    height: 50vh;
    width: 97%;
    /* border:1px solid black; */
    margin: 10px auto;
    background: #FFFFFF;
    border-radius: 24px;
}

.dashboard-right-bottom {
    height: 30vh;
    width: 97%;
    margin: 10px auto;
    /* border:1px solid black; */
    background: #FFFFFF;
    border-radius: 24px;
}

.dashboard-right-bottom .progress-bar {
    height: 90%;
    width: 90%;
    margin: 10px auto;
    /* border:1px solid black; */
}

.progress-bar .progress-bar-title p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.02em;
    color: #15192C;
    margin: -5px 0;
}

.progress-bar .progress-bar-title {
    /* border:1px solid black; */
    height: 30%;
}

.progress-bar .progress {
    height: 90%;
}

.progress-bar .progress-bar-title span {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #92959E;
    margin-top: -20px;
}

.dashboard-right-upper .donut-header {
    height: 20%;
    width: 98%;
    margin: 0 auto;
    /* border-bottom: 1px solid black; */
}

.dashboard-right-upper .donut-body {
    height: 60%;
    width: 98%;
    margin: 0 auto;
    /* border-bottom: 1px solid black; */
}

.donut-body .donut-body-days {
    height: 100%;
    width: 20%;
    /* border:1px solid black; */
}

.donut-body .donut-graph {
    height: 100%;
    width: 80%;
    /* border:3px solid green; */
}

.dashboard-right-upper .donut-footer {
    height: 20%;
    width: 98%;
    margin: 0 auto;
    border-bottom: 1px solid black;
}

.panel-wrapper {
    min-height: 20vh;
    width: 98%;
    margin: 10px auto;
    /* border:1px solid black; */
    background: #FFFFFF;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    gap: 5px;
}

.panel-wrapper .panel-tab {
    min-height: 15vh;
    width: 190px;
    /* border:1px solid black; */
    margin: 5px auto;
}

.panel-tab .panel-upper {
    height: 70%;
    width: 100%;
    /* border:1px solid black; */
}

.panel-tab .panel-bottom {
    height: 30%;
    width: 100%;
    /* border:1px solid black; */
    justify-content: center;
    align-items: flex-end;
}

.panel-upper .panel-icon .icon {
    height: 63px;
    width: 63px;
    opacity: 0.65;
    border-radius: 10px;
}

.panel-bottom .panel-des {
    height: 40px;
    width: 150px;
    /* background: #FFF2E9; */
    opacity: 0.65;
    border-radius: 10px 10px 0 0;
}

.panel-upper .panel-title .panel-text,
.panel-count {
    margin: 8px 0;
}

.content-back-color {
    background: #FFF2E9;
}

.screen-back-color {
    background: #EDE8FF;
}

.playlist-back-color {
    background: #EAF9FF;
}

.user-back-color {
    background: #FFEBEF;
}

/* line graph */
.line-graph-container {
    min-height: 55vh;
    width: 98%;
    margin: 10px auto;
    /* border:1px solid black; */
    background: #FFFFFF;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    gap: 5px;
}

.apexcharts-toolbar {
    display: none !important;
}
