.sidebar-container {
    min-height: 100vh;
    max-width: 100vw;
    border-right: 5px solid #d6d2f7;
    /* opacity: 0.1; */
    display: flex;
    flex-direction: column;
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.sidebar-container .sidebar-upper-sec {
    height: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.sidebar-upper-sec img {
    height: 150px;
    width: 150px;
    object-fit: cover;
}

.sidebar-container .sidebar-middle-sec {
    height: 70vh;
    width: 100%;
    display: flex;
    /* border:1px solid green; */
}

.side-bar-box {
    height: 95%;
    width: 95%;
    margin: 10px auto;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
}

.side-bar-box .links {
    height: 50px;
    width: 200px;
    margin: 10px auto;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    /* background: #F3F0FF; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* .side-bar-box .links:active {
    background: red !important; 
} */



.side-bar-box .links a {
    /* display: flex;
    padding: 10px;
    font-size: 20px;
    text-decoration: none; */
    /* color: black; */
}

/* .side-bar-box .links a:active {
    background-color: #349eff !important;
}

.side-bar-box .links a:visited {
    background-color: #349eff !important;
} */

/* .side-bar-box .links a:hover{
    background-color: #349eff;
    color: white;
    border-radius: 15px;
    margin-right: 30px;
    width: 150px;
} */







.links a {
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: -0.02em;
    color: #B8B8B8;
}

.link-a-color {
    color: #0d0d0d;
    margin: 0 10px;
}

.sidebar-container .sidebar-bottom-sec {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* border:1px solid yellow; */
}

.sidebar-bottom-sec .settings {
    height: 80px;
    width: 80px;
    background: #0d0d0d;
    border-radius: 0px 18px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

a.active {
    background-color: #0d0d0d;
}