.playlist-container {
    /* min-height:70vh; */
    /* max-width: 75vw; */
    /* border:1px solid black; */
    margin: 10px 0;
    display: flex;
    /* justify-content: center; */
    flex-wrap: wrap;
    width: 100%;
}
.user-add-btn {
    width: 273px;
    height: 58px;
    /* border:1px solid black; */
    margin: 0 20px;
}

.user-add-btn button {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: #0d0d0d;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}

.playlist-cards {
    min-height: 60vh;
     /* height: 65vh; */
    width: 48%;
    border-radius: 5px;
    background-color: white;
    /* box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1),
        -1px -1px 5px 0 rgba(0, 0, 0, 0.1); */
    margin: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 16px;
}

.content-all-delete-btn {
    width: 100% !important;
}

.playlist-cards-top {
    min-height: 55vh;
    width: 100%;
    /* margin: 0 auto; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: relative;
}
.playlist-schedule-icon{
    position: absolute;
    height:20px;
    width:20px;
    gap:5px;
    /* border:1px solid black; */
    top:0;
    left:60px;
    
}
.playlist-cards-bottom {
    /* height: 5vh; */
    width: 100%;
    /* margin: 0px auto; */
    /* border:1px solid black; */
    display: flex;
    margin: 22px;
}

.playlist-card-body {
    /* max-height:50vh; */
    /* max-width: 500px; */
    /* margin: 20px auto; */
    overflow-y: auto;
    /* border:1px solid black; */
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    overflow: hidden;
    margin: 20px 10px;
}

.playlist-card-header {
    height: 10vh;
    width: 500px;
    margin: 0px auto;
    border-bottom: 1px solid black;
    display: flex;
}

.playlist-card-header-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.playlist-card-header-left {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.create-playlist-model {
    /* height: 30vh; */
    /* min-width: 30vw; */
    /* border:1px solid black; */
    background-color: white;
}

.create-playlist-model img {
    width: 80px;
    height: 52px;
    margin: 0px auto;
}

.create-playlist-input-field,
.create-playlist-btn-group {
    height: 7vh;
    width: 25vw;
    margin: 10px auto;
    /* border:1px solid black; */
}

.btn-cancel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 108px;
    background: #FFFFFF;
    border: 1px solid #0d0d0d;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
    color: #0d0d0d;
}

.btn-save {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 31px;
    width: 109px;
    background: #0d0d0d;
    color: white;
    border: 1px solid #0d0d0d;
    border-radius: 6px;
    margin: 0 10px;
    outline: none;
    cursor: pointer;
}

/* tabs model */
.main-tab-container {
    height: 80vh;
    /* min-width:90vw; */
    margin: 0 auto;
    border: 1px solid black;
    background-color: #F0F2F5;
}

.content-card-item {
    height: 205px;
    /* width: 47%; */
    width: 240px;
    /* margin: 5px auto; */
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1),
        -1px -1px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    position: relative;
    border-radius: 20px;
    margin: 1%;
}

.content-card-item .upper-sec {
    position: absolute;
    width: 100%;
    margin: 13px auto;
}

.delete-icon {
    height: 35px;
    width: 35px;
    background: #FFFFFF;
    opacity: 0.9;
    border-radius: 10px;
    cursor: pointer;
    color: #0d0d0d;
    margin-right: 10px;
    z-index: 1000;
}

.timer {
    width: 65px;
    height: 35px;
    opacity: 0.9;
    border-radius: 8px;
    cursor: pointer;
    color: #0d0d0d;
    margin-left: 10px;
}
.timer-background{
    background: #FFFFFF;
}

.content-card-item .content-card-item-header {
    height: 10%;
    width: 95%;
    /* border-bottom:1px solid black; */
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.content-card-item .content-card-item-body {
    height: 100%;
    width: 100%;
    /* border-bottom: 1px solid black; */
    margin: 0 auto;
    border-radius: 20px;
}

.content-card-item-body img,
.content-card-item-body video,
.content-card-item-body iframe {
    height: 100%;
    width: 100%;
    border: 3px solid white;
    border-radius: 20px;
    object-fit: cover;
    margin-left: -2px;
    margin-top: -2px;
}

.content-card-item .content-card-item-footer {
    height: 20%;
    width: 95%;
    /* border-bottom:1px solid black; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#days-id input:checked + label{
    border:1px solid white !important;
    background-color: #0d0d0d !important;
    border-radius: 10px !important;
    transition: all .5s ease-in !important;
    color: white;
    /* border-bottom: none;   */
} 
/* .image-content-cards input:checked + label > .image-content-card-footer{
    border:5px solid #404047;
    border-top:none;
} */

@media screen and (min-width:1920px) {
    .playlist-cards {
        min-height: 60vh;
        width: 49%;
        border-radius: 5px;
        background-color: white;
        /* box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1),
            -1px -1px 5px 0 rgba(0, 0, 0, 0.1); 
        /* margin: 10px;*/
        display: flex;
        flex-wrap: wrap; 
    }
    
}
@media screen and (max-width:1000px){
    .playlist-card-body{
        overflow-y: auto;
    /* border:1px solid black; */
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    overflow: hidden;
    margin: 20px 10px;
    }
}