.privacy-container::-webkit-scrollbar {
    display: block;
}

.privacy-container::-webkit-scrollbar-track {
    width: 5px !important;
}

.privacy-container::-webkit-scrollbar-thumb {
    background-color: #551FFF !important;
    border-radius: 5px !important;
}
.privacy-container{
    min-height: 100vh;
    max-width: 99vw;
    margin:0 auto;
}