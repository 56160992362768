.status-badge {
    height: 20px;
    width: 20px;
    border: 2px solid whitesmoke;
    border-radius: 50%;
    margin: 0 auto;
}

.success {
    background-color: green;
}

.danger {
    background-color: red;
}

.screen-table {
    /* margin: 20px auto; */
    width: 100%;
}

.button-controlls {
    height: 100%;
    width: 100%;
    /* border:1px solid black; */
}

.add-screen-btn {
    height: 50px;
    width: 100%;
    background-color: #0d0d0d;
    color: white;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.btn-1 {
    height: 50px;
    width: 50%;
    margin: 5px 5px 0 0;
    background-color: white;
    border: 1px solid #0d0d0d;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.btn-2 {
    height: 50px;
    width: 50%;
    margin: 5px 0 0 0;
    background-color: white;
    border: 1px solid #0d0d0d;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

/* .screen-form{
    height:35vh;
    width:30vw;
} */
.icon-screen {
    color: #0d0d0d;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-screen .screen-icon {
    height: 100%;
    width: 100%;
}